import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import {
  Body,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import Section from "../components/Section"
import Banner from "../components/Banner"
import MainPoint from "../components/MainPoint"

import styled from "styled-components"
import opengraph from "../images/opengraph.png"

const MainPointBullet = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
`

function EngagementPage({ data }) {
  return (
    <Layout pageTitle="Alumni Engagement" image={opengraph}>
      <Banner
        text={[
          <Header1>
            The <Header1Accent>forefront</Header1Accent> of digital engagement
          </Header1>,
          <Header3>
            Give your alumni a personalized experience that adapts to each stage
            of their professional and personal lives.
          </Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
        ]}
        image={data.banner}
      />

      <Section>
        <MainPoint
          text={[
            <Header2>Donor-centric enagement</Header2>,
            <Body>Align your engagement and fundraising strategies.</Body>,
          ]}
          reversed
        />
        <MainPoint
          text={[
            <MainPointBullet>
              <Header5>Increase Willingness to Give</Header5>
              <Body>
                Harness content designed to strengthen emotional connection and
                alumni identity - both proven drivers of giving.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Large-Scale Discovery</Header5>
              <Body>
                CueBack has reimagined alumni engagement to help provide
                actionable insights for personalized cultivation strategies.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Break the 90/9/1 Rule</Header5>
              <Body>
                Traditional social media outlets follow the 90/9/1 Rule, which
                states that only 1% of users are creators of content (= no
                insights for Gift Officers). CueBack proactively breaks this
                rule to gather insights from passive and unengaged alumni.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Data-Driven Decision Making</Header5>
              <Body>
                Don’t rely on guesswork. Use impact analysis data to make
                informed decisions, optimize resources, and maximize giving.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>You Can’t Boil the Ocean</Header5>
              <Body>
                Increase return on effort with the ability to target engagement
                activities at high-value donors and prospects who can move the
                fundraising needle and/or increase your university's brand
                equity.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section1}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Alumni identity</Header2>,
            <Body>
              It's not good enough to just measure alumni identity, we
              proactively increase it.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          reversed
          text={[
            <MainPointBullet>
              <Header5>Reignite Emotional Connection</Header5>
              <Body>
                Enable your alumni to relive the memories that emotionally bind
                them to your institution.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Complete the Story</Header5>
              <Body>
                Despite our best efforts, memories fade. Our patented
                Collaboration feature enables alumni to collectively piece
                together memories and create richer accounts of shared
                experiences.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Share Stories Securely</Header5>
              <Body>
                Let your alumni safely share memorable stories from their
                college days. With CueBack, alumni choose what to share and whom
                to share their stories with.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section2}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Nostalgia works</Header2>,
            <Body>
              Drive engagement and giving with the power of nostalgia.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          text={[
            <MainPointBullet>
              <Header5>Bring Your Archives to Life</Header5>
              <Body>
                Transform your digital archives into an engagement powerhouse
                and connect your alumni to the people, places, traditions, and
                experiences that shaped their lives—and their alumni identity.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Provide Contextual Cues</Header5>
              <Body>
                Leverage thousands of CueBack’s preloaded Memory Cues to elicit
                alumni’s fond memories of their time at your university and
                provide context to what life was like during their college
                years.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Travel Back in Time</Header5>
              <Body>
                Transport your alumni back to their college years by providing
                them with a personalized digital Memory Lane.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Elicit Authentic Stories</Header5>
              <Body>
                CueBack automatically shows personalized prompts to each alum to
                trigger memories and encourage them to write about meaningful
                experiences at their alma mater.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section3}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Lifelong asset for alumni</Header2>,
            <Body>
              Authentic stories are the currency of your alumni community.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          reversed
          text={[
            <MainPointBullet>
              <Header5>Shape the Content</Header5>
              <Body>
                Elicit specific advice about careers and life challenges to
                benefit students and other alumni.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Build a Powerful Asset</Header5>
              <Body>
                Create a repository of insightful alumni stories, knowledge, and
                advice that have ongoing value to your alumni community.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Demonstrate Lifelong Value</Header5>
              <Body>
                Enable your alumni to benefit from the collective knowledge and
                experience of their alumni network throughout their life.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Benefit Multiple Stakeholders</Header5>
              <Body>
                Use custom Prompts to elicit authentic alumni stories for
                Advancement, Recruiting, Athletics, Student Services, and other
                stakeholders.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section4}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Find lost alumni data</Header2>,
            <Body>
              We uncover missing alumni data and enrich your existing alumni
              data so it is up-to-date.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          text={[
            <MainPointBullet>
              <Header5>Employ the Power of Crowdsourcing</Header5>
              <Body>
                How many of your alumni have you lost touch with? We leverage
                your existing alumni network to improve the completeness and
                accuracy of your constituent database.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Uncover High-Value Contributors</Header5>
              <Body>
                Older alumni can be valuable contributors to your institution’s
                brand equity through career support, mentoring, and life advice.
                Often, however, their records are incomplete or out of date.
                Crowdsourcing allows you to easily uncover missing data.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Update Constituent Data</Header5>
              <Body>
                Have confidence that your data is always complete and
                up-to-date. Your CRM is automatically updated with affinity,
                contact, and other useful information when alumni edit their
                profiles.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section5}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: allFile(
      filter: {
        relativePath: { eq: "engagement/sigmund-eTgMFFzroGc-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    section1: allFile(
      filter: { relativePath: { eq: "engagement/herosection-2.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    section2: allFile(
      filter: {
        relativePath: {
          eq: "engagement/leah-hetteberg-Q8cnLQkhiGM-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    section3: allFile(
      filter: {
        relativePath: { eq: "engagement/brett-jordan-vjVe8S6JVvs-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    section4: allFile(
      filter: {
        relativePath: { eq: "engagement/alumniEngagement-section4.jpeg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    section5: allFile(
      filter: {
        relativePath: {
          eq: "engagement/adam-niescioruk-9ChfwZzv4ys-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default EngagementPage
